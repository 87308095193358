import logoLight from "@/images/st-logo.png";
import logoDark from "@/images/st-logo.png";

import flagLight from "@/images/english.png";
import flagDark from "@/images/english.png";
import flageLight from "@/images/germany.png";
import flageDark from "@/images/germany.png";

export const LogoImage = {
  light: logoLight,
  dark: logoDark,
};

export const flagImage = {
  light: flagLight,
  dark: flagDark,
};

export const flageImage = {
  light: flageLight,
  dark: flageDark,
};

export const NavLinks = [
  {
    name: "Ballina",
    url: "/",
  },
  {
    name: "Rreth nesh",
    url: "/about",
  },
  {
    name: "Shërbime",
    url: "/service",
    subItems: [
      {
        name: "Shërbime",
        url: "/service",
      },
      {
        name: "Zhvillimi i faqeve",
        url: "/website-development",
      },
      {
        name: "Dizajn grafik ",
        url: "/website-graphics",
      },
      {
        name: "Marketing dixhital ",
        url: "/digital-marketing",
      },
      {
        name: "Siguria kibernetike",
        url: "/cyber-security",
      },
      {
        name: "Zhvillimi i Aplikacionit",
        url: "/app-development",
      },
    ],
  },
  // {
  //   name: "Portfolio",
  //   url: "/portfolio",
  //   subItems: [
  //     {
  //       name: "Portfolio 01",
  //       url: "/portfolio",
  //     },
  //     {
  //       name: "Portfolio Details",
  //       url: "/portfolio-details",
  //     },
  //   ],
  // },
  // {
  //   name: "Blog",
  //   url: "/blog",
  //   subItems: [
  //     {
  //       name: "Blog 01",
  //       url: "/blog",
  //     },
  //     {
  //       name: "Blog 02",
  //       url: "/blog-2",
  //     },
  //     {
  //       name: "Blog 03",
  //       url: "/blog-3",
  //     },
  //     {
  //       name: "Blog Details",
  //       url: "/blog-single",
  //     },
  //   ],
  // },
  {
    name: "kontakti",
    url: "/contact",
  },
];

const SliderOneData = {
  subTitle: "Mirësevini në Solxk",
  title: "Zhvillim \n  & \n Siguria e internetit",
  button: {
    label: "Zbulo më shumë",
    url: "/about",
  },
  background: "black",
};

export const ContactInfosBlock = {
  subTitle: "Zyrat tona",
  title: "Zyrat afër jush",
  description:
    "Ne përpiqemi t'u ofrojmë klientëve tanë një shërbim të jashtëzakonshëm ndërsa u ofrojmë punonjësve tanë trajnimin më të mirë.",
};

export const ContactInfosList = [
  {
    title: "Pristina",
    infos: [
      {
        name: "10 Pristina",
      },
      {
        name: "needhelp@Solxk.com",
      },
      {
        name: "49 000 000",
      },
    ],
  },
  {
    title: "Pristina",
    infos: [
      {
        name: "10 Pristina",
      },
      {
        name: "needhelp@Solxk.com",
      },
      {
        name: "49 000 000",
      },
    ],
  },
  {
    title: "Pristina",
    infos: [
      {
        name: "10 Pristina",
      },
      {
        name: "needhelp@Solxk.com",
      },
      {
        name: "49 000 000",
      },
    ],
  },
  {
    title: "Pristina",
    infos: [
      {
        name: "10 Pristina",
      },
      {
        name: "needhelp@Solxk.com",
      },
      {
        name: "49 000 000",
      },
    ],
  },
];

export const ContactFormTitle = {
  subTitle: "Kontaktoni me ne",
  title: "Na shkruaj një mesazh",
  description:
    "Ne përpiqemi t'u ofrojmë klientëve tanë një shërbim të jashtëzakonshëm ndërsa u ofrojmë punonjësve tanë trajnimin më të mirë. ",
};

import blogImage1 from "@/images/blog/1.jpg";
import blogImage2 from "@/images/blog/2.jpg";
import blogImage3 from "@/images/blog/3.jpg";
import blogImage4 from "@/images/blog/4.jpg";
import blogImage5 from "@/images/blog/5.jpg";
import blogImage6 from "@/images/blog/6.jpg";

export const BlogData = [
  {
    title: "basic rules of running web agency business",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

import blogImageTwo1 from "@/images/blog/14.jpg";
import blogImageTwo2 from "@/images/blog/15.jpg";

export const BlogTwoData = [
  {
    title:
      "Dynamically procrastinate unique vortals with global best practices.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title:
      "Holisticly conceptualize backend scenarios via accurate technologies.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo2,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

export const BlogHomeSection = {
  subTitle: "our news & articles",
  title: "latest blog posts",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

import blogS1 from "@/images/blog/11.jpg";
import blogS2 from "@/images/blog/12.jpg";
import blogS3 from "@/images/blog/13.jpg";

export const BlogSidebarPost = [
  {
    title: "basic rules of running web agency",
    image: blogS1,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS2,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS3,
    url: "/blog-single",
  },
];

import commentImage1 from "@/images/blog/9.jpg";
import commentImage2 from "@/images/blog/10.jpg";

export const BlogComments = [
  {
    image: commentImage1,
    name: "David Martin",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
  {
    image: commentImage2,
    name: "Jessica Brown",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
];

import portfolio01 from "@/images/portfolio/1.jpg";
import portfolio02 from "@/images/portfolio/2.jpg";
import portfolio03 from "@/images/portfolio/3.jpg";
import portfolio04 from "@/images/portfolio/4.jpg";
import portfolio05 from "@/images/portfolio/5.jpg";
import portfolio06 from "@/images/portfolio/6.jpg";

export const PortfolioData = [
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio01,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio02,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio03,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio04,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio05,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio06,
    url: "/portfolio-details",
  },
];

export const PortfolioFilters = [
  { name: "all" },
  { name: "graphic" },
  { name: "branding" },
  { name: "marketing" },
  { name: "logos" },
];

import portfolioD01 from "@/images/portfolio/7.jpg";
import portfolioD02 from "@/images/portfolio/8.jpg";

export const PortfolioDetailsData = {
  gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
  title: "Design Styles",
  text:
    "Zhvilluesit e zhvilluesve të nullës, asnjë antioksidantë Sapien tani dhimbja kryesore e basketbollit është shumë e jashtme klinike e luanit ekologjik të bananeve.Sapien tani basketbolli i zakonshëm, dhimbja është shumë banane djegës, filmi klinik klinik i luanit klinik klinik klinik të karrotave. ",
  client: "Jessica Brown",
  categories: [
    {
      name: "Graphic,",
      url: "#",
    },
    {
      name: "Illustrations,",
      url: "#",
    },
  ],
  date: "8 Nov, 2018",
  socials: [
    {
      name: "Facebook",
      url: "#",
    },
    {
      name: "Twitter",
      url: "#",
    },
    {
      name: "Linkedin",
      url: "#",
    },
  ],
};

export const ServicePostData = [
  {
    title: "Dizajni modern",
    text:
      "Krijimi i përvojave magjepsëse në ueb me dizajne të hijshme dhe intuitive.",
    iconName: "mei-settings",
    url: "/service-details",
  },
  {
    title: "Produkte dixhitale",
    text:
      "Transformimi i ideve në produkte dixhitale të fundit që magjepsin audiencën..",
    iconName: "mei-transfer",
    url: "/service-details",
  },
  {
    title: "Strategjia e marketingut",
    text:
      "Fuqizoni markën tuaj me strategjitë e marketingut të drejtuara nga të dhënat, të përshtatura për sukses.",
    iconName: "mei-pie-chart",
    url: "/service-details",
  },
];

// export const ServiceHomeTwoData = {
//   subTitle: "welcome to smart meipaly web agency",
//   title: "We design digital products that \n help grow businesses.",
//   text:
//     "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
// };

export const ServiceHomeThreeData = {
  subTitle: "Shërbimet që ne ofrojmë",
  title: "shërbimet tona",
  text:
    "Ne përpiqemi t'u ofrojmë klientëve tanë një shërbim të jashtëzakonshëm ndërsa u ofrojmë punonjësve tanë trajnimin më të mirë.",
};

export const ServicePostTwoData = {
  sectionContent: {
    title: "Zotërimi i zgjidhjeve, \n Përdorimi i Trukeve të Mençura",
    // subTitle: "Solxk",
    text:
      "Ne ofrojmë zgjidhje gjithëpërfshirëse për zhvillimin dhe sigurinë në internet.",
  },
  posts: [
    {
      title: "Zhvillimi i faqeve",
      iconName: "mei-web-design",
      url: "/website-development",
    },
    {
      title: "Grafikat e dizajnuara",
      iconName: "mei-computer-graphic",
      url: "/website-graphics",
    },
    {
      title: "Marketing dixhital",
      iconName: "mei-development-1",
      url: "/digital-marketing",
    },
    {
      title: "Siguria kibernetike",
      iconName: "mei-development",
      url: "/cyber-security",
    },
    {
      title: "Zhvillimi i Aplikacionit ",
      iconName: "mei-app-development",
      url: "/app-development",
    },
  ],
};

import serviceOne01 from "@/images/home_1/code.jpg";
import serviceOne02 from "@/images/home_1/digital.jpg";
import serviceOne03 from "@/images/home_1/cyber.jpg";

export const ServicePostThreeData = {
  sectionContent: {
    title: "Le të inovojmë së bashku",
    subTitle: "Çfarë bëjmë ne",
    text:
      "  Ne përpiqemi t'u ofrojmë klientëve tanë një shërbim të jashtëzakonshëm ndërsa ne mund t'i ofrojmë ekipit tonë mundësi të trajnimit të klasës së parë",
  },
  posts: [
    {
      title: "Zhvillim në internet",
      image: serviceOne01,
      url: "/website-development",
    },
    {
      title: "Marketing dixhital ",
      image: serviceOne02,
      url: "/digital-marketing",
    },
    {
      title: "Siguria e internetit",
      image: serviceOne03,
      url: "/cyber-security",
    },
  ],
};

export const FunfactData = [
  {
    title: "Projektet e përfunduara",
    countNumber: 15,
  },
  {
    title: "Klientë Aktiv",
    countNumber: 12,
  },
  {
    title: "Filxhanë kafeje",
    countNumber: 70,
  },
  {
    title: "Klientë të lumtur",
    countNumber: 39,
  },
];

import trustClient01 from "@/images/home_1/trustt.jpg";

export const TrustClientData = {
  image: trustClient01,
  title: "Ne na kan besuar më shumë se 39 klientë",
  text:
    "Me Solxk, ne jemi krenarë të ofrojmë shërbime të jashtëzakonshme zhvillimi, suksesin dhe inovacionin. Sigurohuni që projektet tuaja të kryhen pa probleme dhe me efikasitet.",
  url: "/about",
};

import ClientCarousel01 from "@/images/client/1.png";
import ClientCarousel02 from "@/images/client/2.png";
import ClientCarousel03 from "@/images/client/3.png";
import ClientCarousel04 from "@/images/client/4.png";
import ClientCarousel05 from "@/images/client/5.png";

export const ClientCarouselData = {
  sectionContent: {
    title: "Ju na besoni",
    subTitle: "klientët tanë",
    text:
      "Ne përpiqemi t'u ofrojmë klientëve tanë një shërbim të jashtëzakonshëm ndërsa u ofrojmë punonjësve tanë trajnimin më të mirë.",
  },
  items: [
    {
      url: "#",
      image: ClientCarousel01,
    },
    {
      url: "#",
      image: ClientCarousel02,
    },
    // {
    //   url: "#",
    //   image: ClientCarousel03,
    // },
    // {
    //   url: "#",
    //   image: ClientCarousel04,
    // },
    // {
    //   url: "#",
    //   image: ClientCarousel05,
    // },
  ],
};

import aboutOne01 from "@/images/about/about1.jpg";
import aboutOne02 from "@/images/about/about22.jpg";

export const AboutOneData = {
  sectionContent: {
    title:
      "Sigurohuni që të njiheni më mirë me kompaninë Solxk me faqen tone",
    subTitle: "Njihuni me ne",
  },
  gallery: [aboutOne01, aboutOne02],
  counter: {
    title: "Kompania filloi",
    number: 2024,
  },
};

import team01 from "@/images/team/1.jpg";
import team02 from "@/images/team/2.jpg";
import team03 from "@/images/team/3.jpg";
import team04 from "@/images/team/4.jpg";
import team05 from "@/images/team/5.jpg";

export const TeamOneData = {
  sectionContent: {
    title: "Ekspertët |",
    subTitle: "Takoj ekipin",
    text:
      "Ekipi ynë përbëhet nga persona pasionantë, secili prej të cilëve do të sjellë talente dhe aftësi unike në tryezë.n Nga zhvilluesit vizionarë deri tek ekspertët vigjilentë të sigurisë kibernetike, secili anëtar i ekipit tonë luan një rol vendimtar në ofrimin e zgjidhjeve të jashtëzakonshme për klientët tanë.",
  },
  posts: [
    {
      image: team01,
      name: "Dede Hyseni",
      designation: "General Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    // {
    //   image: team02,
    //   name: "Fidan Ymeri",
    //   designation: "Senior Developer",
    //   url: "",
    //   socials: [
    //     {
    //       name: "Facebook",
    //       url: "#",
    //     },
    //     {
    //       name: "Twitter",
    //       url: "#",
    //     },
    //     {
    //       name: "Linkedin",
    //       url: "#",
    //     },
    //   ],
    // },
    {
      image: team03,
      name: "Fortesa Krasniqi",
      designation: "Fullstack Developer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Granit Krasniqi",
      designation: "Full Stack Developer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team05,
      name: "Armend Binaku",
      designation: "Cyper Security",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
  ],
};

import video01 from "@/images/about/3.jpg";

export const VideoOneData = {
  sectionContent: {
    title: "Make amazing websites without touching cod",
    subTitle: "the only design you need",
    text:
      "Tincidunt elit magnis nulla facilisis sagittis maecenas. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci. Lorem ipsum dolors sit amet elit magnis amet ultrices purus aliquet.",
  },
  video: {
    image: video01,
    ID: "y2Eqx6ys1hQ",
    title: "Watch Video",
  },
};

import testimonial01 from "@/images/home_1/client.jpg";
import testimonial02 from "@/images/home_1/client.jpg";
import testimonial03 from "@/images/home_1/client.jpg";

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: "klientët tanë",
    title: "kostumerët e kënaqur",
  },
  posts: [
    {
      name: "Cecilia Colon",
      designation: "Drejtor",
      image: testimonial01,
      content:
        "Në Solxk, ne besojmë se ne mund të shndërrojmë vizionet në realitete dixhitale. Çfarë duhet të thonë klientët tanë të lumtur!",
      date: "25 march, 2024",
    },
    {
      name: "Chase Hanson",
      designation: "Bashkëfundimtar",
      image: testimonial02,
      content:
        "Puna me Solxk ishte një pune shume e sukseseshme per ne, ne besojmë se ne mund të shndërrojmë vizionet në realitete dixhitale. Çfarë duhet të thonë klientët tanë të lumtur!",
      date: "14 jan, 2024",
    },
    {
      name: "Mittie Flores",
      designation: "Menaxher",
      image: testimonial03,
      content:
        "Përzgjedhja e Solxk për projektin tonë të zhvillimit të uebit ishte vendimi më i mirë që morëm.",
      date: "15 april, 2024",
    },
    {
      name: "Carrie Sims",
      designation: "Drejtor",
      image: testimonial01,
      content:
        "Falë Solxk, uebfaqja jonë dallohet në një treg konkurrues. Ata kanë zbatuar teknologjitë më të fundit dhe praktikat më të mira, të cilat na kanë dhënë një avantazh konkurrues.",
      date: "25 feb, 2024",
    },
    {
      name: "Anne Stone",
      designation: "Bashkthemelues",
      image: testimonial02,
      content:
        "Ekipi i Solxk e dorëzoi projektin tonë në kohë dhe brenda buxhetit pa kompromentuar cilësinë e procesit të tyre të efektshëm dhe qëndrimin profesional e bëri të gjithë përvojën të qetë dhe pa telashe.",
      date: "6 april, 2024",
    },
    {
      name: "Hunter Brewer",
      designation: "Menaxher",
      image: testimonial03,
      content:
        "Solxk gjeti kohë për të kuptuar markën tonë dhe qëllimet tona. Ata krijuan një faqe interneti që pasqyronte vërtet identitetin tonë dhe angazhoi audiencën tonë personale dhe vëmendja ndaj detajeve",
      date: "10 may, 2024",
    },
  ],
};

import featureTab01 from "@/images/home_1/c22.jpg";
import featureTab02 from "@/images/home_1/c11.png";
import featureTab03 from "@/images/home_1/c33.png";

export const FeatureTabData = {
  sectionContent: {
    title: "Pse na zgjodhet ne",
    subTitle: "Përfitimet tona",
    text:
      "Ne përpiqemi t'u ofrojmë klientëve tanë një shërbim të jashtëzakonshëm ndërsa u ofrojmë punonjësve tanë trajnimin më të mirë.",
  },
  posts: [
    {
      title: "Teknologjia e fundit ",
      content:
        "Zhvillimi i uebit në vitin 2024 përdor teknologjitë më të avancuara për performancën e përmirësuar dhe përvojën e përdoruesit (PWAS) ofrojnë një përvojë të ngjashme me aplikacionin me funksionalitet jashtë linje dhe kohë më të shpejtë të ngarkimit të krijuar duke përdorur korniza si React dhe Vue.js ofron ndërveprime pa probleme me përdoruesit duke ngarkuar në mënyrë dinamike paraqitjen nga ana e serverit (SSR) me korniza si Next.js përmirëson SEO dhe kohën e ngarkimit duke kombinuar përfitimet tradicionale me shumë faqe me spa moderne. ",
      image: featureTab01,
      lists: [
        {
          item: "Rafte i vetë-kontrolluar, më i lartë i teknologjisë së artit",
        },
        {
          item: "Shkallëzueshmëria",
        },

        {
          item: "Aftësi integrimi",
        },
        {
          item: "Masa të forta sigurie",
        },
      ],
    },
    {
      title: "Mbështetje e madhe",
      content:
        "Në Solxk jemi krenarë që u ofrojmë klientëve tanë mbështetje të jashtëzakonshme. Ne e kuptojmë që problemet teknike mund të ndodhin, dhe kur kjo ndodh, ju nevojitet mbështetje e menjëhershme dhe efektive për të mbajtur projektet tuaja pa probleme. Këtu është arsyeja pse mbështetja jonë bie në sy:",
      image: featureTab02,
      lists: [
        {
          item: "Mbështetje e përgjegjshme e mbështetjes",
        },
        {
          item: "Ekspertizë dhe njohuri",
        },
        {
          item: "Zgjidhje të personalizuara",
        },
        {
          item: "Përmirësim të vazhdueshëm",
        },
      ],
    },
    {
      title: "Siguri",
      content:
        "Në Solxk e kuptojmë rëndësinë kritike të sigurisë në peizazhin dixhital të sotëm. Meqenëse kërcënimet kibernetike po zhvillohen me shpejtësi, mbrojtja e të dhënave të ndërmarrjes dhe klientëve tuaj nuk është e negociueshme. Kështu ne sigurojmë qetësinë tuaj shpirtërore: ",
      image: featureTab03,
      lists: [
        {
          item: "Masat gjithëpërfshirëse të sigurisë",
        },
        {
          item: "Zbulimi proaktiv i kërcënimit",
        },
        {
          item: "Përmirësim dhe adaptim i vazhdueshëm",
        },
        {
          item: "Kriptimi i fortë i të dhënave",
        },
      ],
    },
  ],
};
import Solxk from "@/images/home_1/Solxklogo.png";

export const ParallaxOneData = {
  iconName: Solxk,
  title: "ZHYLLIMI I SUKSESIT ME ",
  specialText: "Solxk",
  text:
    "Suksesi nuk është vetëm një destinacion; është një udhëtim që ne fillojmë me çdo klient, çdo projekt dhe çdo risi. Angazhimi ynë për përsosmëri dhe kërkimi ynë i pamëshirshëm i përsosmërisë na kanë shtyrë në ballë të industrisë, duke na fituar njohjen dhe besimin nga klientët në mbarë globin.",
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "planifikim pune",
    subTitle: "Portofoli ynë",
    text:
      "Ne përpiqemi t'u ofrojmë klientëve tanë një shërbim të jashtëzakonshëm ndërsa u ofrojmë punonjësve tanë trajnimin më të mirë.",
  },
};

import video02 from "@/images/home_1/3.jpg";
import aboutTwo01 from "@/images/home_1/2.jpg";
import aboutTwo02 from "@/images/home_1/1.jpg";

export const AboutTwoData = {
  sectionContent: {
    title: "",
    subTitle: "",
    text:
      "Në Solxk nuk jemi thjesht një kompani tjetër. Ne jemi mjeshtër të ekspertizës sonë dhe angazhohemi për strategji inovative për të ofruar zgjidhje të pashembullta. Si një ofrues kryesor i shërbimeve të zhvillimit dhe sigurisë kibernetike, jemi krenarë për angazhimin tonë të palëkundur për përsosmëri.",
    text2:
      "Në Solxk mund të jeni të sigurt se po bashkëpunoni me më të mirët në industri. Nga zhvillimi i teknologjisë deri te zgjidhjet e fuqishme të sigurisë kibernetike, ne ju kemi mbuluar plotësisht. Bashkohuni me ne në rrugën drejt zotërimit të zgjidhjeve dixhitale, ku çdo sfidë përballet me inovacion dhe çdo projekt ekzekutohet me saktësi.",
  },
  button: {
    label: "Mëso më shumë",
    url: "/about",
  },
  gallery: [
    // aboutTwo01,
    aboutTwo02,
  ],
};

import featureTwo01 from "@/images/home_1/5.jpg";

export const FeatureTwoData = {
  sectionContent: {
    title: "përvojë e vërtetë",
    subTitle: "Karakteristikat tona thelbësore",
    text:
      "Ne përpiqemi t'u ofrojmë klientëve tanë një shërbim të jashtëzakonshëm dhe t'u ofrojmë punonjësve tanë trajnimin më të mirë.",
  },
  posts: [
    {
      title: "Nuk kërkojnë aftësi kodimi",
      text:
        "Ka shumë ndryshime të pasazheve nga Lorem ipsum, por shumica vuajtën.",
    },
    {
      title: "Online -Dokumentation",
      text:
        "Es gibt viele Variationen von Passagen von Lorem Ipsum, aber die Mehrheit hat gelitten.",
    },
    {
      title: "SEO optimiert",
      text:
        "Es gibt viele Variationen von Passagen von Lorem Ipsum, aber die Mehrheit hat gelitten.",
    },
  ],
  image: {
    text: "Total Design Freedom \n für jeden.",
    path: featureTwo01,
  },
};

export const CallToActionTwoData = [
  {
    label: "Shikoni punën tonë të fundit",
    url: "/portfolio",
  },
  {
    label: "Kërkoni një ofertë falas",
    url: "/contact",
  },
];
